<template lang="html">
  <div class="bgc-nav">
    <nav class="bgc-nav-bar">
      <div class="bgc-nav-main">
        游戏导航栏
      </div>
    </nav>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.bgc-nav {
  -webkit-font-smoothing: antialiased;

  ol,
  ul {
    list-style: none;
  }
  a {
    color: #212121;
    transition: color 0.3s;
  }
  a:hover {
    text-decoration: none;
    color: #00a1d6;
  }
  a {
    text-decoration: none;
  }
  a:focus,
  *:focus {
    outline: none;
  }

  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
  position: relative;
  z-index: 1000;
  margin: 0;
  padding: 0;
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .bgc-nav-bar {
    min-width: 1160px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .bgc-nav-main {
    background: #24B8F2;
    width: 1160px;
    height: 100%;
    margin: 0 auto;
  }
}
</style>
